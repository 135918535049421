@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Questrial&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Questrial&family=Quicksand:wght@300..700&display=swap');


html{
  background-color: #1f1f1f;
  scroll-behavior: smooth;
}
.font-Playfair-Display{
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.font-quicksand {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.font-inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.text-primary{
  color:#28e98c;
}
.text-secondary{
  color:#999999;
}
.bg-primary{
  background-color: #28e98c;
}
.bg-gray{
  background-color: #888888;
}
.border-primary{
  border-color: #28e98c;
}
.border-gray{
  border-color: #888888;
}
