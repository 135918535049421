.custom-slider-container {
    position: relative;
  }
  
  .custom-slider-prev,
  .custom-slider-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .custom-slider-prev {
    left: 0;
  }
  
  .custom-slider-next {
    right: 0;
  }
  
  .custom-slider-pagination {
    text-align: center;
    margin-top: 10px;
  }
  
  .custom-slider-slide {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
  }
  