  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotating-image {
    animation: rotate 10s linear infinite; /* Adjust the duration and timing function as needed */
  }